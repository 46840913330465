/* eslint-disable no-loop-func */
import { Costants } from "./Costants";

export const QueryAdvSearch = (filtersQuery) => {

  const arrayMade = (array, type, operator) => {
    let stringForQuery = "";
    let op = operator ? operator : ",";
    let divisor = "'";
    let arrayStructure =
      type === "geographic"
        ? "en"
        : type === "country"
        ? "country_ISO3_Code"
        : "";
    for (var i = 0; i < array.length; i++) {
      if (arrayStructure !== "") {
        if (i === array.length - 1) {
          stringForQuery += `${array[i][arrayStructure]}`;
        } else {
          stringForQuery += `${array[i][arrayStructure]} ${op} `;
        }
      } else {
        if (i === array.length - 1) {
          stringForQuery += `${array[i]}`;
        } else {
          stringForQuery += `${array[i]}${op}`;
        }
      }
    }

    return stringForQuery;
  };

  const queryMade = (queryOption) => {
    let querymade = "";
    for (let el of Costants.mapQuery) {
      if (
        el !== "topic" &&
        el !== "typeoftext" &&
        el !== "record" &&
        el !== "mainareas" &&
        queryOption[el]
      ) {
        querymade += `${el}:(${queryOption[el]}) `;
      } else if (el === "record") {
        querymade += `${queryOption[el]} `;
      } else if (
        (el === "topic" && queryOption[el]) ||
        (el === "mainareas" && queryOption[el]) || 
        (el === "typeoftext" && queryOption[el])
      ) {
        if (!queryOption[el].includes(",")) {
          querymade += `${el}:(${queryOption[el]}) `;
        } else {
          const selArraySplit = queryOption[el].split(",");
          selArraySplit.forEach((tpc, index) => {
            if (index === selArraySplit.length - 1) {
              querymade += `${el}:(${tpc})`;
            } else {
              querymade += `${el}:(${tpc}) OR `;
            }
          });
        }
      }
    }
    return querymade;
  };

  const facetsMade = (facets) => {
    let facetmade = "";
    for (let el of Costants.facetsMap) {
      for (let ft of facets) {
        if (ft.typeLoad === el.field) {
          facetmade += `${el.field}:(${ft.name.split("#")[0]}) `;
        }
      }
    }

    return facetmade;
  };

  const yearsQueryMade = (yearsOption) => {
    let yearsMade = "";

    if (yearsOption.yearfrom && yearsOption.yearto) {
      yearsMade += `(${Costants.yearFromMap[0]}:${yearsOption.yearfrom} ${Costants.yearToMap[0]}:${yearsOption.yearto}) | `;
      yearsMade += `(${Costants.yearFromMap[1]}:${yearsOption.yearfrom} ${Costants.yearToMap[1]}:${yearsOption.yearto})`;
    } else if (yearsOption.yearfrom && !yearsOption.yearto) {
      yearsMade += `(${Costants.yearFromMap[0]}:${yearsOption.yearfrom}) | (${Costants.yearFromMap[1]}:${yearsOption.yearfrom})`;
    } else if (!yearsOption.yearfrom && yearsOption.yearto) {
      yearsMade += `(${Costants.yearToMap[0]}:${yearsOption.yearto}) | (${Costants.yearToMap[1]}:${yearsOption.yearto})`;
    }

    return yearsMade;
  };

  const queryBuild = () => {
    if (filtersQuery) {
      var andArray = ["AND","ET","Y","和","И","و"];
      console.log("operator title "+filtersQuery.operatorTitle)
      var operatorTitle = (andArray.includes(filtersQuery.operatorTitle))?" AND ":" OR ";
      var operatorCountry = (andArray.includes(filtersQuery.operatorCountry))?" AND ":" OR ";
      var operatorTerritorial = (andArray.includes(filtersQuery.operatorTerritorial))?" AND ":" OR ";
      var wordsfromrecords = filtersQuery.record;
      var wordsfromtitle = filtersQuery.title.replace(
        " ",
        operatorTitle
      );
      var topic = filtersQuery.topic;
      var primarySubjects = arrayMade(filtersQuery.primarySubjects);
      var type = filtersQuery.type;
      var country = arrayMade(
        filtersQuery.country,
        "country",
        operatorCountry
      );
      var territorial = arrayMade(
        filtersQuery.geographic,
        "geographic",
        operatorTerritorial
      );
      var excluderepealed = filtersQuery.repealed;
    }

    return `{
      "${Costants.mapQuery[0]}": "${wordsfromrecords ? wordsfromrecords : ""}",
      "${Costants.mapQuery[1]}": "${wordsfromtitle ? wordsfromtitle : ""}",
      "${Costants.mapQuery[2]}": "${topic ? topic : ""}",
      "${Costants.mapQuery[3]}": "${primarySubjects ? primarySubjects : ""}",
      "${Costants.mapQuery[4]}": "${country ? country : ""}",
      "${Costants.mapQuery[5]}": "${territorial ? territorial : ""}",
      "${Costants.mapQuery[6]}": "${excluderepealed ? excluderepealed : ""}",
      "${Costants.mapQuery[7]}": "${type ? type : ""}"
      }`;
  };

  var year = `{
    "${Costants.yearFromMap[0]}": "${filtersQuery.yearFrom}",
    "${Costants.yearToMap[0]}": "${filtersQuery.yearTo}"
  }`;

  var page = filtersQuery.page === -1 ? 0 : (filtersQuery.page - 1) * 10;

  let query = queryMade(JSON.parse(queryBuild()));
  let facet = facetsMade(filtersQuery.facets);
  let years = yearsQueryMade(JSON.parse(year));

  let facetOptions = [
    { operatorName: "facetyear", numFacetBuckets: 100 },
    { operatorName: "countries", numFacetBuckets: 100 },
    { operatorName: "mainareas", numFacetBuckets: 100 },
    { operatorName: "subjectselections", numFacetBuckets: 100 },
    { operatorName: "keywords", numFacetBuckets: 100 },
    { operatorName: "geographicalareas", numFacetBuckets: 100 },
    { operatorName: "typeoftexts", numFacetBuckets: 100 },
    { operatorName: "documentlanguages", numFacetBuckets: 100 },
    { operatorName: "territorialsubdivisions", numFacetBuckets: 100 },
    { operatorName: "organization", numFacetBuckets: 100 },
    { operatorName: "topic", numFacetBuckets: 100 },
    { operatorName: "type", numFacetBuckets: 100 },
  ];

  let queryAdvSearch = "";

  if (filtersQuery.sort !== "relevance") {
    queryAdvSearch = {
      query: `* ${years} ${query} ${facet}`,
      requestOptions: {
        searchApplicationId:
          "searchapplications/1be285f8874b8c6b09629075d87b057f",
      },
      facetOptions: facetOptions,
      sortOptions: {
        operatorName: "byyear",
        sortOrder: "DESCENDING",
      },
      start: page,
    };
  } else {
    queryAdvSearch = {
      query: `* ${years} ${query} ${facet}`,
      requestOptions: {
        searchApplicationId:
          "searchapplications/1be285f8874b8c6b09629075d87b057f",
      },
      facetOptions: facetOptions,
      start: page,
    };
  }

  return JSON.stringify(queryAdvSearch);
};
